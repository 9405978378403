/*
 * @Author: gaojingran
 * @Date: 2021-04-01 13:54:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-03 14:27:02
 * @Description: 表单校验
 */
import i18n from '@/i18n'
import moment from 'moment'
import zxcvbn from 'zxcvbn'

export default {
  required: {
    required: true,
    message: i18n.t('validate.required'),
  },
  email: {
    pattern: /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+\.([a-zA-Z0-9_-]+)(\.[a-z]+)*$/,
    message: i18n.t('validate.format', { attr: i18n.t('email') }),
  },
  no_gt_email: {
    pattern: /(?<!pactera.com|gientech.com)$/,
    message: i18n.t('validate.no_gt_email'),
  },
  int: { pattern: /^[0-9]*[1-9][0-9]*$/, message: i18n.t('validate.int') },
  float_2: {
    pattern: /^\d+(\.\d{1,2})?$/g,
    message: i18n.t('validate.flost_size', { size: 2 }),
  },
  float_3: {
    pattern: /^\d+(\.\d{1,3})?$/g,
    message: i18n.t('validate.flost_size', { size: 3 }),
  },
  min_string_6: {
    min: 6,
    message: i18n.t('validate.min_size', { size: 6 }),
    trigger: 'blur',
  },
  length_3_18: {
    min: 3,
    max: 18,
    message: i18n.t('validate.length_range', { min: 3, max: 18 }),
  },
  length_3_50: {
    min: 3,
    max: 50,
    message: i18n.t('validate.length_range', { min: 3, max: 50 }),
  },
  size_10: {
    max: 10,
    message: i18n.t('validate.max_size', { size: 10 }),
  },
  size_20: {
    max: 20,
    message: i18n.t('validate.max_size', { size: 20 }),
  },
  size_200: {
    max: 200,
    message: i18n.t('validate.max_size', { size: 200 }),
  },
  size_500: {
    max: 500,
    message: i18n.t('validate.max_size', { size: 500 }),
  },
  size_1000: {
    max: 1000,
    message: i18n.t('validate.max_size', { size: 1000 }),
  },
  // 日期大于当前时间
  earlier_time: {
    validator: (rule_, value, callback) => {
      if (value && moment(value).isBefore(moment().format('YYYY-MM-DD HH:mm:ss'))) {
        callback(i18n.t('validate.earlier_time'))
      } else {
        callback()
      }
    },
  },
  // 日期大于24小时之后
  earlier_next_24_hours: {
    validator: (rule_, value, callback) => {
      if (value && moment(value).isBefore(moment().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss'))) {
        callback(i18n.t('validate.earlier_next_hours', { hours: 24 }))
      } else {
        callback()
      }
    },
  },
  // 日期大于36小时之后
  earlier_next_36_hours: {
    validator: (rule_, value, callback) => {
      if (value && moment(value).isBefore(moment().add(36, 'hours').format('YYYY-MM-DD HH:mm:ss'))) {
        callback(i18n.t('validate.earlier_next_hours', { hours: 36 }))
      } else {
        callback()
      }
    },
  },
  safe_password: {
    validator: (rule_, value, callback) => {
      const { score } = zxcvbn(value)
      if (score < 3) {
        callback(i18n.t(`login.password_score_${score}`))
      } else {
        callback()
      }
    },
    trigger: 'blur',
  },
  taxIdNumber: {
    pattern: /^[A-z0-9]*$/,
    message: i18n.t('validate.format', { attr: i18n.t('taxIdNumber') }),
  },
  only_num_letter: {
    pattern: /^[A-z0-9]*$/,
    message: i18n.t('validate.only_num_letter'),
  },
  /**
   * \u00a0\u0020\u3000 空格
   * \u0028\u0029 英文括号
   * \uff08\uff09 中文括号
   */
  regular_str_less_size_30: {
    pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9_\u00a0\u0020\u3000\u0028\u0029\uff08\uff09]{1,30}$/,
    message: i18n.t('validate.regular_str_less_size', { size: 30 }),
  },
  regular_str_less_size_50: {
    pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9_\u00a0\u0020\u3000\u0028\u0029\uff08\uff09]{1,50}$/,
    message: i18n.t('validate.regular_str_less_size', { size: 50 }),
  },
  regular_str_less_size_100: {
    pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9_\u00a0\u0020\u3000\u0028\u0029\uff08\uff09]{1,100}$/,
    message: i18n.t('validate.regular_str_less_size', { size: 100 }),
  },
  only_zh_en_num: {
    pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
    message: i18n.t('validate.only_zh_en_num'),
  },
  only_num: {
    pattern: /^[0-9]*$/,
    message: i18n.t('validate.only_num'),
  },
  decimal_0_1: {
    pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^[0-1]$/,
    message: i18n.t('validate.decimal_0_1'),
  },
}
